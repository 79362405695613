import React, { useState } from "react";
import hexToRgba from "hex-to-rgba";
import { observer } from "mobx-react";

import themes from "components/themes";
import { Badge, Box } from "@mui/material";
import { SavedLinksPanel } from "@upptic/module-ui";
import FavoriteIcon from "@mui/icons-material/Favorite";

// -- COMPONENT
function SavedLinks(): JSX.Element {
  const [sectionHovered, setSectionHovered] = useState(false);

  return (
    <Box
      onMouseEnter={() => setSectionHovered(true)}
      onMouseLeave={() => setSectionHovered(false)}
      sx={{
        position: "relative",
        borderBottom: "4px solid transparent",
        borderColor: sectionHovered ? themes.colors.purple : "transparent",
        backgroundColor: sectionHovered
          ? hexToRgba(themes.colors.purple, 0.3)
          : "none",
        width: "54px",
        height: "60px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Badge color="primary">
        <FavoriteIcon />
      </Badge>
      {sectionHovered ? (
        <Box
          sx={{
            maxHeight: "calc(100vh - 130px)",
            height: sectionHovered ? "auto" : 0,
            transition: "height 0.4s",
            width: "600px",
            position: "absolute",
            top: "60px",
            right: 0,
            backgroundColor: themes.colors.backgroundLight,
          }}
        >
          <Box mb={1.5}>
            <SavedLinksPanel />
          </Box>
        </Box>
      ) : null}
    </Box>
  );
}

export const SavedLinksElement = observer(SavedLinks);
